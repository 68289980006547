import { FC } from "react";
import * as _ from "lodash";
import { useFormikContext } from "formik";

import { useTranslation, Subscriptions as Content } from "@ctra/i18n";
import { Form, Select, Typography, Alert, Input, Checkbox, Space } from "@ctra/components";
import { DHMSDetails } from "@ctra/api";

import { FormObserver } from "../FormObserver";
import styles from "./DHMSForm.module.less";
import { dhmsFormKey } from "../const";
import { GACategories } from "../../analytics";

const { Paragraph, Text } = Typography;

/**
 * DHMS form
 * @return {JSX.Element}
 * @constructor
 */
export const DHMSForm: FC = () => {
  const { t } = useTranslation();

  const {
    values: { dhms }
  } = useFormikContext<DHMSDetails>();

  const {
    gettingStarted: {
      dhms: {
        form: { smallPrint, link, alert, labels }
      }
    }
  } = Content;

  /**
   * List of DHMS
   * @type {string[]}
   */
  const dhmsList = [
    "CRV",
    "Minda",
    "UniformAgri",
    "DairyComp (DC305)",
    "BoviSync",
    "herdePlus",
    "dairyPlan",
    "PC Dart",
    "Riska",
    "DelPro",
    "Vampp",
    "Alpro",
    "Crystal",
    "Agrovision",
    "Lely (API)",
    "Seges",
    "Ifarm",
    "other"
  ];

  return (
    <section className={styles.Wrapper}>
      <FormObserver keyField={dhmsFormKey} />
      <Form layout="vertical">
        <Form.Item required name="dhms" label={t<string>(labels.dhms)} hasFeedback={false}>
          <Select data-gtm-category={GACategories.gettingStarted} data-gtm-action="Select DHMS" name="dhms">
            {_.map(dhmsList, (dhms) => (
              <Select.Option
                data-gtm-category={GACategories.gettingStarted}
                data-gtm-info={dhms}
                key={dhms}
                value={dhms}
              >
                {dhms}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Paragraph>
          {t<string>(smallPrint)}{" "}
          <a
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Check supported integrations"
            rel="noreferrer"
            href="https://www.connecterra.ai/features-pages/data-integration#:~:text=We%20integrate%2040%2B%C2%A0of%20the%20most%20popular%20systems%20in%20the%20industry"
            target="_blank"
          >
            {t<string>(link)}
          </a>
        </Paragraph>
        {dhms === "other" ? (
          <section style={{ marginBottom: "16px" }}>
            <Alert showIcon message={t<string>(alert)} type="info" style={{ marginBottom: "16px" }} />
            <Form.Item required name="other" label={t<string>(labels.other)} hasFeedback={false}>
              <Input
                data-gtm-category={GACategories.gettingStarted}
                data-gtm-action="Type other DHMS"
                type="text"
                name="other"
              />
            </Form.Item>
            <Space>
              <Checkbox
                name="notification"
                data-gtm-category={GACategories.gettingStarted}
                data-gtm-action="Check 'notify me' checkbox"
              />
              <Text>{t<string>(labels.checkbox)} </Text>
            </Space>
          </section>
        ) : null}
      </Form>
    </section>
  );
};
